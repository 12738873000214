import * as React from 'react'
import EmploymentProjectCard from './employment-project-card';
import EmployerCard from './employer-card';
import Seo from '../seo';

const EmploymentTimeline = ( props ) => {
  const employers = props.props;

  //Contains the jsx employment history generated for the timeline
  let employmentHistory = [];

  //When this is updated, animations on children are triggered where needed.
  const [selectedEmployer, setSelectedEmployer] = React.useState(new Map());

  //Handles event passed along from employer when the userselects an employer.  Updates selected employer;
  const onEmployerSelect = React.useCallback((event) => {
    setSelectedEmployer((event.target.name === selectedEmployer) ? undefined : event.target.name);
  }, [selectedEmployer]);

  //Itterate over employers graphql returned and create nodes for the timeline.
  employers.forEach( (employer, index ) => {
    
    //Holds projects for the
    let projects = [];

    //Generates projects for the employer
    employer.projects.forEach( ( project ) => {
      projects.push(
        <EmploymentProjectCard key={project.id} 
          summary={project.projectSummary} 
          contributions={project.contributions} 
          role={project.role} 
          industry={project.industry} 
          name={project.projectName} 
          projectId={project.id}
          isEven={`${(index%2===0) ? true : false}`}
          lastOne={(index === (employers.length - 1))}
        />
      )
    })
    
    //Generate employment history
    employmentHistory.push(
      <EmployerCard key={index} 
        employer={employer} 
        projects={projects} 
        isEven={(index%2===0) ? true : false} 
        startDate={new Date(`${employer.startDate}T00:00:00`).toLocaleDateString('en-us', {year: 'numeric', month: 'long'})} 
        endDate={new Date(`${employer.endDate}T00:00:00`).toLocaleDateString('en-us', {year: 'numeric', month: 'long'})} 
        onEmployerSelect={onEmployerSelect} 
        selectedEmployer={selectedEmployer}
        lastOne={(index === (employers.length - 1))}
      />
    )
  })

  return (
    <ul className="m-0 p-0 pt-5">
      {employmentHistory}
    </ul>
  )
}

export default EmploymentTimeline;

export const Head = (props) => { 
  return (
    <Seo 
      keywords={props.data.Seo.keywords}
      title={props.data.Seo.title} 
      description={props.data.Seo.description} 
      seoImage={props.data.Seo.seoImage.url}
      favicon={props.data.Seo.favicon.url}  
      url={`https://www.traviscucore.com/professional-history`}
    />
  ) 
}